html {
  height: 100%;
}
body, body::before, body::after {
  height: 100vh;
  margin: 0;
  background-repeat: repeat;
  background-attachment: fixed;
}
body::before, body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity .3s ease;
}
  
body::before {
  background-image: radial-gradient( circle farthest-corner at -4% -12.9%, rgb(74, 110, 88) 0.3%, rgb(30, 48, 32) 90.2%);
  opacity: 1;
}

body.change::before {
  opacity: 0;
}

body::after {
  opacity: 0;
  background-image: radial-gradient(circle 2759px at -6.7% 50%, rgba(80, 131, 73, 1) 0%, rgba(140, 209, 131, 1) 26.2%, rgba(178, 231, 170, 1) 50.6%, rgba(144, 213, 135, 1) 74.1%, rgba(75, 118, 69, 1) 100.3%);
}

body.change::after {
  opacity: 1;
}

.AppLight, .AppDark {
  text-align: center;
  /* transition: .3s ease; */
}

.AppDark {
  color: lightgrey;
}

.AppLight h1, .AppDark h1 {
  margin-top: 100px;
}

.AppDark a {
  color: white
}

.AppLight a {
  color: seagreen
}

.navLight, .navDark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0%;
  width: 100%;
  overflow: hidden;
  z-index: 100;
  transition: .3s ease;
  background-color: lightgrey;
  padding: 5px 20px;
  box-shadow: 0 0 10px black;
  /* gap: 50px; */
  /* position: fixed; */
}

.navDark {
  background-color: rgb(50, 50, 50);
  box-shadow: 0 0 10px grey;
}

nav h1 {
  padding: 10px 0;
  /* font-size: 18px; */
  margin-right: 10px;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 50px
}

.navLight ul a, .navLight a {
  text-decoration: none;
  color: black;
  /* font-size: 1.5rem; */
}

.navDark ul a, .navDark a {
  text-decoration: none;
  color: lightgrey;
  /* font-size: 1.5rem; */
}

.navDark h1, .navDark h4 {
  color: lightgrey
}

.footerLight, .footerDark {
  width: 100%;
  margin: 0 auto;
  background-color: lightgrey;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px black;
  text-decoration: none;
  color: rgb(50,50,50);
  padding: 10px 0;
  position: fixed;
  bottom: 0%;
  text-align: center;
  z-index: 100;
  transition: .3s ease;

}

.footerDark {
  background-color: rgb(50, 50, 50);
  color: lightgrey;
  box-shadow: 0 0 10px lightgrey;
}
.content, .contentDark {
  margin: 0 auto;
  text-align: center;
  background-color: lightgrey;
  padding: 20px;
  border-radius: 50px;
  width: 300px;
  gap: 50px;
  transition: .3s ease;

}

.contentDark {
  border: 1px solid;
  background-color: rgb(50, 50, 50);
  /* color: lightgrey; */
  
}

.contentDark p {
  color: lightgrey;
  /* transition: .3s ease; */
}

.content h3, .content h4 {
  font-weight: bold
}

.content a {
  color: seagreen;
}

.contentDark a, .contentDark h3, .contentDark h4 {
  color: lightgrey;
  font-weight: bold;
  /* transition: .3s ease; */
}

.hide {
  display: none;
}

.mainDivLight a {
  color: rgb(50,50,50);
}

.mainDivDark a {
  color: white;

}

.flex {
  display: flex;
}

table, th, td {
  border: 1px solid;
  padding: 10px;
  /* margin: auto */
}

.tableDark th {
  border: 1px solid white;
}

.regLog {
  border: 1px solid;
  padding: 10px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: rgb(50, 50, 50);
  color: lightgrey;
}

.darkText {
  color: rgb(50,50,50)
}

.idea {
  line-height: 2;
  /* border-radius: 10px; */
}

.tab {
  /* border: 2px solid black; */
  /* width: 100px; */
  /* height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.clicked {
  background-color: seagreen;
  color: white;
}

.MQHide {
  display: none
}

.popup {
  border: 1px solid;
  position: fixed;
  width: 200px;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* top: 25%; */
  background-color: rgb(50,50,50);
  padding: 25px;
  border-radius: 40px;
  /* transition: opacity 10s ease; */
  z-index: 100;
}

.pizzaBox {
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid black;
  padding: 20px;
}

/* Custom pagination colors vv */
.custom-pagination .page-link {
  color: seagreen;
  /* background-color: #00ff11;
  border-color: #04ff00; */
}

.custom-pagination .page-link:hover {
  color: #fff;
  background-color: #00904f7c;
  border-color: #000;
}

.custom-pagination .page-link:focus {
  color: #fff;
  background-color: seagreen;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem rgba(38, 255, 49, 0.5);
}

.custom-pagination .page-item.active .page-link {
  color: #fff;
  background-color: seagreen;
  border-color: #000;
}

.custom-input:focus {
  border-color: #80ff8d;
  box-shadow: 0 0 0 0.2rem rgba(0, 255, 13, 0.25);
}
/* Custom pagination colors ^^ */

.landingPage {
  width: 300px;
  margin: 0 auto
}

.profilePicture {
  border-radius: 100%;
}

.navLight .darkModeButton {
  transition: .3s ease;
}

.navDark .darkModeButton {
  transition: .3s ease;
}

.darkmodeL, .darkmodeD {
  position: fixed;
  bottom: 10%;
  left: 5%;
  border-radius: 100%;
  border: 0px;
  /* box-shadow: 5px 5px 5px grey; */
  background-color: lightgrey;
  padding: 10px 12px;
}
.darkmodeD {
  /* box-shadow: 5px 5px 5px lightgrey; */
  background-color: rgb(50, 50, 50);
  padding: 10px 14px;
}

/* @media (width <= 545px) {
  .navLight, .navDark {
    padding: 5px 20px;
  }
} */

@media (width <= 545px) {
  .MQHide {
    display: block
  }

  nav h1, nav h4, nav a, nav span {
    font-size: 1rem;
    /* color: red; */
  }

  .navLight, .navDark {
    justify-content: space-evenly;
    padding: 5px;

  }
}

@media (width < 900px) {
  .ideaList {
    margin-left: 10%;
  }
}

@media (width > 899px) {
  .popup {
    width: 500px;
  }

  /* .content {
    width: 924px;
  }
  .contentDark {
    width: 924px;
  } */
  .landingPage {
    width: 924px;
    margin: 0 auto;
    gap: 50px;
  }
}

@media (width < 500px) {

  table, th, td {
    font-size: 14px;
  }

  .tableDark th {
    font-size: 10px;
  }
}

@media (width<320px) {
  nav, footer, .content, .contentDark {
    width: 100%;
  }
  table h5, th, td {
    font-size: 12px;
  }

  .tableDark th {
    font-size: 8px;
  }
}
